import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import './style.scss';
import { Link } from 'react-router-dom';
// Assets
import Header from './../Header';
import Arrow from './../../images/arrow.svg';
import Wavevertical from './../../images/waveabt.png';
// import BackgroundRemove from './../../images/background-remove.png';
import Github from './../../images/github.svg';
import Gitlab from './../../images/gitlab.svg';
import Bootstrap from './../../images/bootstrap.svg';
import JavaScript from './../../images/js.svg';
import ReactR from './../../images/react.svg';
import Python from './../../images/python.svg';
import Docker from './../../images/docker.svg';
import Django from './../../images/django.svg';
import HTML from './../../images/html.svg';
import CSS from './../../images/css.svg';

function About() {
  let app = useRef(null);
  let slider = useRef(null);
  let content = useRef(null);
  let skills = useRef(null);
  let tl = gsap.timeline();

  useEffect(() => {
    const headlineFirst = content.children[0].children[0];
    const headlineSecond = headlineFirst.nextSibling;
    const headlineThird = headlineSecond.nextSibling;
    const contentP = content.children[1];
    const contentButton = content.children[2];

    gsap.to(app, 0, { css: { visibility: 'visible' } });

    // ====== SLIDER ANIMATION ====== //
    gsap.fromTo(
      slider,
      { autoAlpha: 0 },
      {
        duration: 5,
        y: 20,
        autoAlpha: 0.5,
        ease: 'slow.out',
        delay: 0.9
      }
    );

    // ====== CONTENT ANIMATION ====== //
    gsap.from(
      [headlineFirst.children, headlineSecond.children, headlineThird.children],
      { duration: 1, y: 44, ease: 'slow.out', stagger: 0.3, delay: 0.4 },
      'start'
    );
    gsap.fromTo(
      contentP,
      { autoAlpha: 0 },
      {
        duration: 1,
        y: 20,
        autoAlpha: 1,
        ease: 'slow.out',
        delay: 1.5
      }
    );
    gsap.fromTo(
      contentButton,
      { autoAlpha: 0 },
      { duration: 1, y: 20, autoAlpha: 1, ease: 'slow.out', delay: 1.6 }
    );
    gsap.fromTo(
      skills,
      { autoAlpha: 0 },
      { duration: 1, y: 20, autoAlpha: 1, ease: 'slow.out', delay: 1.3 }
    );
  }, [tl]);

  return (
    <>
      <Header />
      <div className='about'>
        <div className='hero' ref={el => (app = el)}>
          <div className='container'>
            <div className='hero-inner'>
              <div className='hero-content'>
                <div className='hero-content-inner' ref={el => (content = el)}>
                  <h1>
                    <div className='hero-content-line'>
                      <div className='hero-content-line-inner'>
                        welcome to my <span className='pink'>P</span>ortfolio
                      </div>
                    </div>
                    <div className='hero-content-line'>
                      <div className='hero-content-line-inner'>
                        i am <span className='pink'>M</span>onika Malecka
                      </div>
                    </div>
                    <div className='hero-content-line'>
                      <div className='hero-content-line-inner'>
                        & i am an aspiring <span className='pink'>F</span>
                        rontend Developer
                      </div>
                    </div>
                  </h1>
                  <p>
                    From September 2019 to November 2019, I enrolled in a
                    3-month Full-Stack Development course in Zürich where I
                    learned frontend technologies: JavaScript, React & Redux and
                    backend technologies: Python, Django & Docker. During the
                    course I deepend my passion for creating beautifully
                    designed apps and I am currently looking for an internship
                    that focuses on frontend development.
                  </p>
                  <div className='btn-row'>
                    <Link to='/projects'>
                      <button className='explore-button'>
                        Projects
                        <div className='arrow-icon'>
                          <img src={Arrow} alt='row' />
                        </div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>

              <div className='hero-skills'>
                <div className='hero-skills-inner' ref={el => (skills = el)}>
                  <h1>
                    <div className='hero-content-line'>
                      <div className='hero-skills-line-inner'>
                        some things I worked with along the way...
                      </div>
                    </div>
                  </h1>

                  <div className='hero-icon js'>
                    <img src={JavaScript} alt='' />
                    <p>JavaScript</p>
                  </div>
                  <div className='hero-icon react'>
                    <img src={ReactR} alt='' className='icon' />
                    <p>React & Redux</p>
                  </div>
                  <div className='hero-icon python'>
                    <img src={Python} alt='' />
                    <p>Python</p>
                  </div>
                  <div className='hero-icon django'>
                    <img src={Django} alt='' />
                    <p>Django</p>
                  </div>
                  <div className='hero-icon docker'>
                    <img src={Docker} alt='' />
                    <p>Docker</p>
                  </div>
                  <div className='hero-icon github'>
                    <img src={Github} alt='' />
                    <p>Github</p>
                  </div>
                  <div className='hero-icon gitlab'>
                    <img src={Gitlab} alt='' />
                    <p>Gitlab</p>
                  </div>

                  <div className='hero-icon html'>
                    <img src={HTML} alt='' />
                    <p>HTML</p>
                  </div>
                  <div className='hero-icon html'>
                    <img src={CSS} alt='' />
                    <p>CSS</p>
                  </div>
                  <div className='hero-icon bootstrap'>
                    <img src={Bootstrap} alt='' />
                    <p>Bootstrap</p>
                  </div>
                </div>
              </div>
              <div
                className='slider'
                ref={el => (slider = el)}
                style={{
                  backgroundImage: `url(${Wavevertical})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  opacity: '0.1'
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
