import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import About from './../components/About';
import Projects from './../components/Projects';
import Contact from './../components/Contact';
import AboutInfo from './../components/AboutInfo';
import WorkInProgress from './../components/WorkInProgress';

class Routes extends Component {
  state = {};
  render() {
    return (
      <>
        <Route exact path='/' component={About} />
        <Route exact path='/about-info' component={AboutInfo} />
        <Route exact path='/projects' component={WorkInProgress} />
        <Route exact path='/contact' component={Contact} />
      </>
    );
  }
}

export default Routes;
