import React, { useRef, useEffect } from 'react';
import './style.css';
import IceCream from '../../images/ice-cream-sm.png';
import Header from '../Header';

const WorkInProgress = () => (
  <>
    <Header />
    <div className='progress'>
      <h1 className='pink'>Work in progress...</h1>
      <img src={IceCream}></img>
    </div>
  </>
);

export default WorkInProgress;
