import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import './style.css';
import Header from '../Header';
import Jura3 from '../../images/jura3.png';
import Todo2 from '../../images/todo2.png';
import Starwars from '../../images/sws.png';

function Projects() {
  let projectIntro = useRef(null);
  let tl = gsap.timeline();

  useEffect(() => {
    // gsap.to(app2, 0, { css: { visibility: 'visible' } });

    tl.from(
      projectIntro,
      { duration: 1, y: 20, opacity: 0, ease: 'slow.out' },
      0.4
    );
  });

  return (
    <>
      <Header />
      <div className='projects-container' ref={el => (projectIntro = el)}>
        <div className='projects-container-inner'>
          <div className='projects-intro'>
            <h4 >
              The Project section encompasses both course work, as well as
              Juragarage website built for a client.
              <br></br>
              My Portfolio was built using React & Redux.
            </h4>
          </div>
          <div className='col' >
            <div className='col-left'>
              <a
                href='https://www.juragarage.ch/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={Jura3} alt='' />
              </a>
            </div>
            <div className='col-right'>
              <a
                href='https://www.juragarage.ch/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <h3>Juragarage Märki</h3>
              </a>

              <p className='short-desc'>
                Website created for a Swiss car-dealer.
              </p>
              <p className='col-tech'>
                <span className='pink'>Technologies used: </span>HTML, CSS,
                Bootstrap, JavaScript
              </p>
            </div>
          </div>
          <div className='line'></div>
          <div className='col'>
            <div className='col-left'>
              <a
                href='https://jolly-hermann-d0b4d9.netlify.com'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={Todo2} alt='' />
              </a>
            </div>

            <div className='col-right'>
              <a
                href='https://jolly-hermann-d0b4d9.netlify.com'
                target='_blank'
                rel='noopener noreferrer'
              >
                <h3>Todo App</h3>
              </a>
              <p className='short-desc'>
                Propulsion Academy course project. A simple todo app.
              </p>
              <p className='col-tech'>
                <span className='pink'>Technologies used: </span>React & Redux
              </p>
            </div>
          </div>

          <div className='line'></div>
          <div className='col'>
            <div className='col-left'>
              <a
                href='https://distracted-darwin-128a3b.netlify.com/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={Starwars} alt='' />
              </a>
            </div>

            <div className='col-right'>
              <a
                href='https://distracted-darwin-128a3b.netlify.com/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <h3>Star Wars API</h3>
              </a>
              <p className='short-desc'>
                Propulsion Academy course project. Using Star Wars API to fetch
                data.
              </p>
              <p className='col-tech'>
                <span className='pink'>Technologies used: </span>React, Axios,
                Async/Await
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Projects;
