import React, { Component } from 'react';
import './style.css';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

class Header extends Component {
  state = {};
  render() {
    return (
      <div className='header'>
        <div className='header-container'>
          <Link to='/'>
            <div className='header-name'>MONIKA MALECKA</div>
          </Link>

          <div className='header-right'>
            <NavLink
              to='/about-info'
              style={
                this.props.location.pathname === '/about-info'
                  ? { color: '#e3a8b2' }
                  : { color: '#5c6470' }
              }
            >
              <li>ABOUT</li>
            </NavLink>
            <NavLink
              to='/projects'
              style={
                this.props.location.pathname === '/projects'
                  ? { color: '#e3a8b2' }
                  : { color: '#5c6470' }
              }
            >
              <li>PROJECTS</li>
            </NavLink>
            <NavLink
              to='/contact'
              style={
                this.props.location.pathname === '/contact'
                  ? { color: '#e3a8b2' }
                  : { color: '#5c6470' }
              }
            >
              <li>CONTACT</li>
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}

// export default Header;
export default withRouter(Header);
