import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import './style.css';
import Header from '../Header';
import Linkedin from '../../images/linkedin.svg';
import Github from '../../images/github.svg';
import Wavepink from '../../images/wavenew.png';

function AboutInfo() {
  let about = useRef(null);
  let aboutHeader = useRef(null);
  let aboutInfo = useRef(null);
  let aboutContact = useRef(null);
  let wavepink = useRef(null);
  let tl = gsap.timeline();

  useEffect(() => {
    gsap.to(about, 0.2, { css: { visibility: 'visible' } });

    gsap.fromTo(
      aboutHeader,
      { autoAlpha: 0 },
      { duration: 1, y: 20, autoAlpha: 1, ease: 'slow.out', delay: 0.8 }
    );

    gsap.fromTo(
      aboutInfo,
      { autoAlpha: 0 },
      {
        duration: 1,
        y: 20,
        autoAlpha: 1,
        ease: 'slow.out',
        delay: 0.8
      }
    );

    gsap.fromTo(
      aboutContact,
      { autoAlpha: 0 },
      {
        duration: 1,
        y: 20,
        autoAlpha: 1,
        ease: 'slow.out',
        delay: 1
      }
    );

    gsap.fromTo(
      wavepink,
      { autoAlpha: 0 },
      {
        duration: 2,
        y: 20,
        autoAlpha: 0.2,
        ease: 'slow.out',
        delay: 1
      }
    );
  }, [tl]);

  return (
    <>
      <Header />
      <div className='about-info' ref={el => (about = el)}>
        <div ref={el => (aboutHeader = el)}>
          <h1>
            about me <span className='pink'></span>
          </h1>
        </div>

        <div ref={el => (aboutInfo = el)}>
          <p className='about-short'>
            Cześć, I am Monika and I come from Poland. I have been living in
            beautiful Switzerland for three years and during this time I have
            been working at an executive search company -
            <a
              href='https://www.kennedyfitch.com/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <span className='pink'> KennedyFitch </span>
            </a>
            in Zürich, currently as an Office Manager. <br></br>Thinking of my
            next career move, I enrolled in a Full-Stack development course at
            <a
              href='https://propulsion.academy/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <span className='pink'> Propulsion Academy </span>
            </a>
            in Zürich from September 2019 to November 2019. <br></br>However,
            the curiosity about programming didn’t start there. My first steps
            into the programming world started with Codecademy and their online
            Intensive Web Development Course. That experience yielded the first
            web development project for a real client - a
            <a
              href='https://www.juragarage.ch/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <span className='pink'> website </span>
            </a>
            built using HTML, CSS, Bootstrap and some JavaScript for a Swiss car
            dealer. <br></br>The next big step was joining the Propulsion
            Academy which I found to be one of the most challening but very
            enriching three months of my life. Studying in the bootcamp was
            filled with hard work but it made me certain of the path I would
            like to follow - I found passion for frontend development. <br></br>
            I’m currently looking for an internship that will give me an
            opportunity to learn and grow within this completely new, but
            already somewhat familiar world :)
            <br />
            P.S. What is it like working with me? There may be occasional cakes,
            talks about food, music and cats.
          </p>
        </div>
        <div ref={el => (aboutContact = el)}>
          <div className='about-info-linkedin top'>
            <p>More about my professional background: </p>
            <a
              href='https://www.linkedin.com/in/monika-malecka/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={Linkedin} alt='linkedin-logo' />
            </a>
          </div>
          <div className='about-info-linkedin'>
            <p>Github Profile:</p>
            <a
              href='https://github.com/monixm'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={Github} alt='github-logo' />
            </a>
          </div>
        </div>
        <div
          className='wavepink'
          ref={el => (wavepink = el)}
          style={{
            backgroundImage: `url(${Wavepink})`,
            backgroundPosition: 'contain',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            opacity: '0.6'
          }}
        ></div>
      </div>
    </>
  );
}

export default AboutInfo;
