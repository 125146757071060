import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import './style.css';
import Header from '../Header';
import Wave from '../../images/wave.png';

function Contact() {
  let app2 = useRef(null);
  let contentContact = useRef(null);
  let wave = useRef(null);
  let tl = gsap.timeline();

  useEffect(() => {
    gsap.to(app2, 0, { css: { visibility: 'visible' } });

    tl.from(
      contentContact,
      { duration: 1, y: 20, opacity: 0, ease: 'power3.out' },
      0.7
    ).from(wave, { duration: 1, y: 20, opacity: 0, ease: 'slow.out' }, 1);
  });

  return (
    <>
      <Header />
      <div className='contact' ref={el => (app2 = el)}>
        <div className='contact-content' ref={el => (contentContact = el)}>
          <h1>
            <div>
              l<span className='pink'>e</span>t's get in touch
              <span className='pink'>!</span>
            </div>
          </h1>
          <h2>
            <a
              href='mailto:monika.malecka19@gmail.com'
              target='_blank'
              rel='noopener noreferrer'
            >
              monika.malecka19<span className='pink'>@</span>gmail.com
            </a>
          </h2>
        </div>
      </div>
      <div
        className='wave'
        ref={el => (wave = el)}
        style={{
          backgroundImage: `url(${Wave})`,
          backgroundPosition: 'contain',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          opacity: '0.2'
        }}
      ></div>
    </>
  );
}

export default Contact;
